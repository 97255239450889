import { useEffect } from 'react';

const Redirect = () => {
  useEffect(() => {
    const checkFor404 = async () => {
      const currentUrl = window.location.href;

      // Vérifiez si l'URL est incorrecte ou contient /audrey ou /defaultsite
      if (currentUrl.includes('/audrey') || currentUrl.includes('/defaultsite')) {
        window.location.replace('https://www.audrey-brelle-nutrition-sante.fr/');
      }

      // Effectuer une requête pour vérifier le statut de la page
      try {
        const response = await fetch(currentUrl, { method: 'HEAD' });
        if (response.status === 404) {
          // Redirigez vers la bonne URL en cas d'erreur 404
          window.location.replace('https://www.audrey-brelle-nutrition-sante.fr/');
        }
      } catch (error) {
        // En cas d'erreur de réseau, rediriger aussi
        window.location.replace('https://www.audrey-brelle-nutrition-sante.fr/');
      }
    };

    checkFor404();
  }, []);

  return null; // Ou un composant de chargement si nécessaire
};

export default Redirect;

